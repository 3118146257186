import React from "react";
import { Link } from "gatsby";

export default function ButtonLink({ children, to }) {
  return (
    <Link
      to={to}
      className="rounded-md px-3 py-2 transition-all
                 duration-150 ease-out border border-brand-blue
                 text-brand-blue hover:bg-brand-blue hover:text-white"
    >
      {children}
    </Link>
  );
}
