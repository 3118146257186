import React from "react";
import authorEmric from "../assets/author-emric.jpg";
import Container from "../templates/ui/Container";
import bannerImage from "../assets/bannerImage.jpg";
import DefaultLayout from "../templates/ui/DefaultLayout";
import Helmet from "react-helmet";
import ButtonLink from "../templates/ButtonLink";

export default function IndexPage() {
  return (
    <DefaultLayout>
      <Helmet>
        <title>Emric Månsson Blog</title>
        <meta
          name="description"
          content="Fun tutorials and articles all things programming."
        />
      </Helmet>

      <div
        className="h-56 w-full bg-center"
        style={{ backgroundImage: `url(${bannerImage})` }}
      ></div>

      <Container>
        <div className="space-y-16">
          <section>
            <img
              src={authorEmric}
              className="rounded-full w-48 mb-5 h-48 border-4 -mt-32"
              alt="Emric smiling with arms crossed"
            />

            <h1 className="text-gray-900 text-4xl mb-3">Who goes there?</h1>
            <p className="text-gray-800 mb-5 leading-relaxed">
              My name is Emric Månsson and I code. Here is where you will find
              stuff I deem cool enough to present. Feel free to browse the
              content. For business inquiries, questions or just fun, please
              contact me at{" "}
              <a
                className="text-brand-blue"
                href="mailto:emric.mansson@gmail.com"
              >
                emric.mansson@gmail.com
              </a>
            </p>
            <ButtonLink to="/posts">Go to blog &#8594;</ButtonLink>
          </section>
        </div>
      </Container>
    </DefaultLayout>
  );
}
